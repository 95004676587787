import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import { groupDetailUrl } from '../../../groups/GroupDetail/GroupDetail';
import { getText } from '../../../i18n/Text';
import { getUniqueKey, GroupStudy } from '../../../types/GroupStudy';
import Product from '../../../types/Product';
import SimpleGroup from '../../../types/SimpleGroup';
import SimpleModule from '../../../types/SimpleModule';
import { WidgetField, WidgetType } from '../../Widget';
import { FieldSection } from './FieldSection';

type Props = {
  field: WidgetField;
  type: WidgetType;
  product: Product;
  hideHeader?: boolean;
  isTitle: boolean;
};

class GroupItem {
  groupStudy: GroupStudy;
  name: string;
  key: string;

  constructor(groupStudy: GroupStudy) {
    this.groupStudy = groupStudy;
    this.name = `${getText(groupStudy.group.data.names)} (${groupStudy.study.data.code})`;
    this.key = getUniqueKey(groupStudy);
  }
}

async function loadGroups(product: Product) {
  let items: GroupStudy[] = [];

  if (product instanceof SimpleModule) {
    items = await SimpleModule.groups(product);
  } else if (product instanceof SimpleGroup) {
    items = await SimpleGroup.parents(product);
  }

  return items
    .map((item) => new GroupItem(item))
    .sort((item1, item2) => item1.name.localeCompare(item2.name));
}

export function GroupsItem({
  field,
  type,
  product,
  hideHeader,
  isTitle
}: Readonly<Props>) {
  const state = useQuery(
    ['productGroups', product.data.id, product.data.year.id],
    () => loadGroups(product)
  );

  return (
    <Loader state={state}>
      {(items) => (
        <FieldSection
          field={field}
          type={type}
          isEmpty={isEmpty(items)}
          hideHeader={hideHeader}
          isTitle={isTitle}
        >
          {() => (
            <>
              {items.map((item) => (
                <Link
                  key={item.key}
                  to={groupDetailUrl(item.groupStudy.group.data)}
                  className="d-block"
                >
                  {item.name}
                </Link>
              ))}
            </>
          )}
        </FieldSection>
      )}
    </Loader>
  );
}
