import { ReactNode, useEffect, useState } from 'react';
import { InfoTooltip } from '@42.nl/ui';
import classNames from 'classnames';
import { QuickviewItem } from '../../../components/Quickview/components/QuickviewItem';
import { QuickviewTitle } from '../../../components/Quickview/components/QuickviewTitle';
import { getText } from '../../../i18n/Text';
import { getLabel, WidgetField, WidgetType } from '../../Widget';

type Props = {
  field: WidgetField;
  type?: WidgetType;
  isEmpty: boolean;
  hideHeader?: boolean;
  sideBarClasses?: string;
  viewClasses?: string;
  children: () => ReactNode;
  isTitle: boolean;
};

export function FieldSection({
  field,
  type,
  isEmpty,
  hideHeader,
  sideBarClasses,
  viewClasses,
  children,
  isTitle
}: Readonly<Props>) {
  const [tooltip, setTooltip] = useState<string>();

  useEffect(() => {
    setTooltip(getText(field.tooltips, undefined));
  }, [field.tooltips]);

  if (isEmpty && field.hideEmpty) {
    return null;
  }

  if (type === WidgetType.PRIMARY) {
    return isTitle ? (
      <QuickviewTitle>{children()}</QuickviewTitle>
    ) : (
      <QuickviewItem
        hideLabel={hideHeader}
        label={getLabel(field)}
        tooltip={tooltip}
      >
        {children()}
      </QuickviewItem>
    );
  }

  if (type !== WidgetType.SIDEBAR) {
    return (
      <section className="details__content__main__section">
        {!hideHeader ? (
          <div className="d-flex flex-row">
            <h2 className="details__content__main-name d-flex flex-column flex-grow-1 mt-2">
              {getLabel(field)}
            </h2>
          </div>
        ) : null}
        {tooltip ? <p>{tooltip}</p> : null}
        <div className={viewClasses}>{children()}</div>
      </section>
    );
  }

  return (
    <section
      className={classNames('details__content__section d-flex', sideBarClasses)}
    >
      <div className="flex-grow-1 font-base-strong me-3">
        {getLabel(field)}
        {tooltip ? (
          <InfoTooltip
            tooltip={tooltip}
            className="ms-1 text-primary"
            size={15}
          />
        ) : null}
      </div>
      <div
        className={`details__content__main__section__value text-end ${viewClasses || 'text-break'}`}
      >
        {children()}
      </div>
    </section>
  );
}
